import {Box, Grid, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent,
	CyclisHeaderComponent as CyclisHeader,
	CustomTableComponent as CustomTable,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {getCyclists} from '@templates/Home/cyclists-slice'
import {LanguageContext} from '@utils/context'
import {useTableDataProvider} from '@utils/hooks'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {CommonProps, Cyclist} from '@utils/types'

import useStyles from './style.hook'

/**
 * SearchResult
 */
interface SearchResultLocation extends Location {
	state: {
		searchTerm: string
		cyclists: Cyclist[]
	}
}

interface SearchResultProps extends CommonProps {
	location: SearchResultLocation
}

const SearchResult: React.FC<SearchResultProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	const [sort, setSort] = useState({
		direction: SortDirection.DESC,
		property: 'cyclistFirstName',
	})
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [cyclists, setCyclists] = useState<Cyclist[]>([])

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const storeCyclists = useSelector(
		(state: RootState) => state.cyclists.cyclists
	)

	const [tableData] = useTableDataProvider({
		data: cyclists && cyclists.length > 0 ? cyclists : storeCyclists,
		tableSort: sort,
		paginateThreshold: 100,
	})

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		const urlParamsSearchTerm = urlParams.get('search')

		if (urlParamsSearchTerm) {
			setSearchTerm(urlParamsSearchTerm)
		}

		if (location && location.state) {
			setCyclists(location.state.cyclists)
		}

		if (!cyclists || cyclists.length === 0) {
			dispatch(getCyclists(urlParamsSearchTerm!))
		}
	}, [location])

	const goBack = (): void => {
		dispatch(navigate(`/${activeLanguage}/app`))
	}

	const tableCells = [
		{
			id: 0,
			label: t('SearchResultHeaderSoNum'),
			propertyName: 'salesOrderNumber',
			toBodyClass: (): string =>
				classNames(classes.tableValue, classes.purpleTableValue),
			toBodyValue: (cyclist: Cyclist): string => cyclist.salesOrderNumber,
			toTooltipValue: (cyclist: Cyclist): string =>
				cyclist.salesOrderNumber.toString(),
		},
		{
			id: 1,
			label: t('SearchResultHeaderCyclist'),
			propertyName: 'cyclistFirstName',
			toBodyClass: (): string => classes.tableValue,
			toBodyValue: (cyclist: Cyclist): string =>
				`${cyclist.cyclistFirstName} ${cyclist.cyclistLastName}`,
			toTooltipValue: (cyclist: Cyclist): string =>
				`${cyclist.cyclistFirstName} ${cyclist.cyclistLastName}`,
		},
		{
			id: 2,
			label: t('SearchResultHeaderCompany'),
			propertyName: 'customer',
			toBodyClass: (): string => classes.tableValue,
			toBodyValue: (cyclist: Cyclist): string => cyclist.customer,
			toTooltipValue: (cyclist: Cyclist): string => cyclist.customer,
		},
		{
			id: 3,
			label: t('SearchResultHeaderBicycle'),
			propertyName: 'brand',
			toBodyClass: (): string => classes.tableValue,
			toBodyValue: (cyclist: Cyclist): string =>
				`${cyclist.brand} ${cyclist.model}`,
			toTooltipValue: (cyclist: Cyclist): string =>
				`${cyclist.brand} ${cyclist.model}`,
		},
	]

	const changeSort = (property: string): void => {
		// Flip sorting order if same property
		if (property === sort.property) {
			setSort({
				...sort,
				direction:
					sort.direction === SortDirection.DESC
						? SortDirection.ASC
						: SortDirection.DESC,
			})
		} else {
			// Else set new property as sorting property and default descending order
			setSort({
				direction: SortDirection.DESC,
				property,
			})
		}
	}

	const clickResultRow = (cyclist: Cyclist): void => {
		dispatch(
			navigate(
				`/${activeLanguage}/app/cyclist-profile?cyclist=${
					cyclist.cyclistId
				}&bicycle=${cyclist.bicycleId}${searchTerm && `&search=${searchTerm}`}`
			)
		)
	}

	const ResultTable: React.FC = () => (
		<Box
			id={`search-result-table-container`}
			className={classes.tableContainer}
		>
			<CustomTable
				id={'search-result'}
				columnsData={tableCells}
				tableEntries={tableData}
				sort={sort}
				handleCellClick={clickResultRow}
				changeSort={changeSort}
			/>
		</Box>
	)

	return (
		<TemplateContainerComponent
			id={'search-result-container'}
			className={classes.root}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Grid container spacing={8}>
				<Grid item xs={2} />
				<Grid item xs={8}>
					<Box className={classes.mainContentContainer}>
						<Box
							id={'search-result-go-back-container'}
							className={classes.backToOverviewContainer}
							onClick={goBack}
						>
							<ArrowBackIcon id={'search-result-go-back-icon'} />
							<Typography
								id={'search-result-go-back-text'}
								className={classes.backToOverviewText}
								variant={'body1'}
							>
								{t('SearchResultPageBackToOverview')}
							</Typography>
						</Box>
						<Typography
							id={'search-result-title'}
							className={classes.searchResultText}
							variant={'body1'}
						>
							{`${t('SearchResultPageTitle')} '${searchTerm}'`}
						</Typography>
						<ResultTable />
					</Box>
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</TemplateContainerComponent>
	)
}

SearchResult.propTypes = {
	location: PropTypes.any.isRequired,
}

export default SearchResult
