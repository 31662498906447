import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {startDeliveryDocumentDownload} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {downloadFileFromUrl} from '@utils/download-helper'
import {Bicycle, CyclisDocumentType} from '@utils/types'

type DeliveryDocumentDownloadHookReturn = () => void

const useDeliveryDocumentDownload = (
	bicycle: Bicycle
): DeliveryDocumentDownloadHookReturn => {
	const deliveryDocumentDownloadUrl = useSelector(
		(state: RootState) => state.orderFlow.deliveryDocumentDownloadUrl
	)

	const dispatch = useDispatch()

	const downloadDocument = (): void => {
		const documentId = bicycle.attachments.find(
			(attach) =>
				attach.documentType === CyclisDocumentType.UNSIGNED_DELIVERY_DOCUMENT
		)?.documentId
		dispatch(startDeliveryDocumentDownload(bicycle.bicycleId, documentId!))
	}

	/**
	 * Download delivery document when it becomes available
	 */
	useEffect(() => {
		if (deliveryDocumentDownloadUrl) {
			downloadFileFromUrl(deliveryDocumentDownloadUrl)
		}
	}, [deliveryDocumentDownloadUrl])

	return downloadDocument
}

export default useDeliveryDocumentDownload
