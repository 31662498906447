import {useEffect} from 'react'

import {NO_GOOGLE_TRANSLATE} from '@utils/constants/util-constants'

interface QuerySelectorNotranslateProps {
	query: string
	toWatch?: any
}

const useQuerySelectorAllNotranslate = ({
	query,
	toWatch,
}: QuerySelectorNotranslateProps): void => {
	useEffect(() => {
		const domElements = document.querySelectorAll(query)
		domElements.forEach((domEl) => domEl.classList.add(NO_GOOGLE_TRANSLATE))
	}, [toWatch])
}

export default useQuerySelectorAllNotranslate
