import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside SearchResult functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	mainContentContainer: {
		marginTop: theme.spacing(60 / 8),
	},
	backToOverviewContainer: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
	},
	backToOverviewText: {
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 16,
		fontWeight: 'bold',
		color: '#08080d',
		marginLeft: theme.spacing(13 / 8),
	},
	searchResultText: {
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 36,
		fontWeight: 'bold',
		color: '#08080d',
		marginTop: theme.spacing(30 / 8),
	},
	tableHeaderTitle: {
		color: '#7e7e80',
		fontWeight: 600,
		fontSize: 16,
	},
	tableContainer: {
		marginTop: theme.spacing(32 / 8),
		width: `calc(100%)`,
		'& .MuiTable-root': {
			borderCollapse: 'separate',
			borderSpacing: theme.spacing(0, 8 / 8),
		},
		'& .MuiTableBody-root': {
			'& .MuiTableCell-root': {
				border: 'solid 1px rgba(0, 0, 0, 0.16)',
				borderRight: 'none',
				borderLeft: 'none',
			},
			'& .MuiTableRow-root': {
				'& .MuiTableCell-root': {
					'&:first-child': {
						borderTopLeftRadius: 8,
						borderBottomLeftRadius: 8,
						borderLeft: 'solid 1px rgba(0, 0, 0, 0.16)',
					},
					'&:last-child': {
						borderTopRightRadius: 8,
						borderBottomRightRadius: 8,
						borderRight: 'solid 1px rgba(0, 0, 0, 0.16)',
					},
				},
			},
		},
	},
	tableValue: {
		fontSize: 16,
		color: '#08080d',
	},
	purpleTableValue: {
		color: '#524fa2',
		fontWeight: 500,
	},
	headerTableCell: {
		borderBottom: 'none',
		paddingTop: 22,
	},
	headerTitleSortWrapper: {
		display: 'flex',
		cursor: 'pointer',
	},
	tableBodyCell: {
		borderBottom: 'none',
		paddingTop: theme.spacing(18 / 8),
		paddingBottom: theme.spacing(18 / 8),
	},
	tableBodyRow: {
		cursor: 'pointer',
	},
	cardEffect: {
		backgroundColor: '#ffffff',
		borderRadius: theme.spacing(8 / 8),
		border: `solid 1px rgba(0, 0, 0, 0.16)`,
	},
	sortIcon: {
		color: '#7e7e80',
		height: theme.spacing(15 / 8),
		marginBottom: theme.spacing(2 / 8),
		alignSelf: 'center',
	},
	hidden: {
		visibility: 'hidden',
	},
}))

export default useStyles
