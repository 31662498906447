export const downloadFileFromUrl = (
	url: string,
	filename?: string
): HTMLAnchorElement => {
	// Create a new anchor element
	const a = document.createElement('a')

	// Set the href and download attributes for the anchor element
	// You can optionally set other attributes like `title`, etc
	// Especially, if the anchor element will be attached to the DOM
	a.href = url
	a.target = '_blank'
	a.download = filename || 'download'

	// Programmatically trigger a click on the anchor element
	// Useful if you want the download to happen automatically
	// Without attaching the anchor element to the DOM
	// Comment out this line if you don't want an automatic download of the blob content
	a.click()

	// Return the anchor element
	// Useful if you want a reference to the element
	// in order to attach it to the DOM or use it in some other way
	return a
}

export const downloadFileFromString = (
	content: string,
	filename?: string
): HTMLAnchorElement => {
	// Create a new anchor element
	const file = new Blob([content], {type: 'application/csv'})
	const a = document.createElement('a')

	// Set the href and download attributes for the anchor element
	// You can optionally set other attributes like `title`, etc
	// Especially, if the anchor element will be attached to the DOM
	a.href = URL.createObjectURL(file)
	a.target = '_blank'
	a.download = filename || 'download'

	// Programmatically trigger a click on the anchor element
	// Useful if you want the download to happen automatically
	// Without attaching the anchor element to the DOM
	// Comment out this line if you don't want an automatic download of the blob content
	a.click()

	// Return the anchor element
	// Useful if you want a reference to the element
	// in order to attach it to the DOM or use it in some other way
	return a
}
